import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  TextField
} from '@mui/material';
import Icon from './Icon'; 
import { fetchSummaryById } from '../services/SummaryService';
import { useTheme } from '@mui/material';
import EditSummaryButton from '../components/Button/EditSummaryButton'; // newly created
import ENDPOINTS from '../utils/apiConfig'; 

const SummaryDetail = ({ summary, onBack }) => {
  const [detailedSummary, setDetailedSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [newSummaryText, setNewSummaryText] = useState('');

  const theme = useTheme();

  useEffect(() => {
    if (summary?.id) {
      setIsLoading(true);
      fetchSummaryById(summary.id)
        .then((data) => {
          setDetailedSummary(data);
          setNewSummaryText(data.summary_text); // load text into the editing state
        })
        .catch((err) => {
          console.error('Failed to fetch summary detail:', err);
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [summary]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Save new text to the server (Patch request)
  const handleSaveClick = async () => {
    if (!detailedSummary?.id) return;

    try {
      const accessToken = localStorage.getItem('accessToken');
      const res = await fetch(ENDPOINTS.SUMMARY_EDIT(detailedSummary.id), {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({ summary_text: newSummaryText })
      });

      if (!res.ok) {
        const errData = await res.json();
        console.error('Error patching summary:', errData);
        throw new Error(errData.detail || 'Fehler beim Aktualisieren der Zusammenfassung');
      }

      // If success:
      const updatedData = await res.json();
      setDetailedSummary(updatedData);           // update local detail
      setNewSummaryText(updatedData.summary_text);
      setIsEditing(false);
    } catch (err) {
      console.error('Error saving summary:', err);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ mt: 4 }}>
        Error: {error}
      </Typography>
    );
  }

  if (!detailedSummary) {
    return null;
  }

  return (
    <Box sx={{ p: 2, minHeight: '100vh', position: 'relative' }}>
      {/* Header with summary name and top-right icons */}
      <Box sx={{ position: 'relative', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {detailedSummary.name}
        </Typography>

        {/* Close button */}
        <IconButton
          onClick={onBack}
          sx={{
            marginLeft: 'auto',
            width: 30,
            height: 30,
            padding: 0,
            position: 'absolute',
            top: 16,
            right: 16,
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent'
            }
          }}
        >
          <Icon iconName="cross" style={{ width: 30, height: 30 }}/>
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Paper
  elevation={3}
  sx={{
    width: {
      xs: '90%',
      sm: '80%',
      md: '65%',
      lg: '55%',
      xl: '50%',
    },
    height: '78vh',
    display: 'flex',
    flexDirection: 'column',  // So we can have top header, scrollable body, bottom footer
    borderRadius: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(0, 0, 0, 0.1)'
  }}
>
  {/* 
    1) NON-SCROLLABLE HEADER FOR EDIT/SAVE BUTTON
  */}
  <Box
    sx={{
      flexShrink: 0,
      p: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }}
  >
    {isEditing ? (
      <IconButton
        onClick={handleSaveClick}
        sx={{
          width: 30,
          height: 30,
          p: 0,
          color: 'grey',
          '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
          }
        }}
      >
        <Icon iconName="checkCircle" style={{ width: 30, height: 30 }} />
      </IconButton>
    ) : (
      <EditSummaryButton onClick={handleEditClick} />
    )}
  </Box>

  {/* 
    2) SCROLLABLE CONTENT 
      - extra right padding so scrollbar isn’t flush with edge
      - minHeight: 0 to allow shrinking
  */}
  <Box
    sx={{
      flexGrow: 1,
      minHeight: 0,
      overflowY: 'auto',
      px: 3,        // left & right padding
      pb: 3,        // bottom padding
      '&::-webkit-scrollbar': { width: '0.5em' },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        borderRadius: '4px',
      },
    }}
  >
    {isEditing ? (
      <TextField
        value={newSummaryText}
        onChange={(e) => setNewSummaryText(e.target.value)}
        multiline
        fullWidth
        minRows={10}
        variant="outlined"
        sx={{ textAlign: 'left' }}
      />
    ) : (
      <Typography
        variant="body1"
        sx={{
          textAlign: 'left',
          lineHeight: 1.6,
          whiteSpace: 'pre-line',
        }}
      >
        {detailedSummary.summary_text}
      </Typography>
    )}
  </Box>

  {/* 
    3) NON-SCROLLABLE FOOTER (just white space)
      - optional border-top to visually separate
  */}
  <Box
    sx={{
      flexShrink: 0,
      minHeight: 20,    
   
    }}
  />
</Paper>

</Box>
    </Box>
  );
};

export default SummaryDetail;
