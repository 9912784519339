import React from 'react';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  LinearProgress,
  Tooltip
} from '@mui/material';
import Icon from '../../components/Icon';
import EditQuestionSetButton from '../Button/EditQuestionSetButton';
import DeleteQuestionSetButton from '../Button/DeleteQuestionSetButton';

function LinearProgressWithLabel({ value, sx, ...props }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 8, // Adjust thickness if needed
            borderRadius: '10px', // Round the outer progress bar
            backgroundColor: (theme) => theme.palette.grey[300], // Optional: Customize background color
            '& .MuiLinearProgress-bar': {
              borderRadius: '10px', // Round the progress itself
            },
            ...sx, // Allow additional custom styles
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'grey.700' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


const QuizList = ({
  questionSets,
  // questionsError, // We no longer use a global error blocking the entire list
  onQuestionSetClick,
  onQuestionSetUpdated,
  courseName,
  isQuizReady,
  quizProgress,
}) => {
  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: { md: '400px', lg: '500px', xl: '600px' },
        width: '100%',
        borderRadius: '20px',
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'left',
          pl: 1,
          pb: 2,
          backgroundColor: 'background.paper',
          width: '100%',
        }}
      >
        {courseName} - Meine Fragesets
      </Typography>

      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          '&::-webkit-scrollbar': {
            width: '0.5em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '4px',
          },
        }}
      >
        {/* If no question sets exist, show a fallback */}
        {questionSets.length === 0 ? (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2,
            }}
          >
            Du hast noch keine Fragestes erstellt.
          </Typography>
        ) : (
          questionSets
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((questionSet) => {
              // We'll check the questionSet's status to see what UI to display
              const isFailed = questionSet.questions_status === 'failed';
              const isInProgress = questionSet.questions_status === 'in_progress';

              return (
                <Box
                  key={questionSet.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    p: 2,
                    width: '100%',
                    '&:hover': {
                      backgroundColor: 'grey.200',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => onQuestionSetClick(questionSet)}
                >
                  {/* Left side: Icon + Name + (Status UI) */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      minWidth: 0, // allow text truncation
                      flexShrink: 1,
                      flexGrow: 1,
                      overflow: 'hidden',
                    }}
                  >
                    {/* Icon + Name Container */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        minWidth: 0,
                        flexShrink: 0,
                        overflow: 'hidden',
                      }}
                    >
                      <Icon iconName="quiz" style={{ height: 30, width: 30 }} />

                      <Tooltip title={questionSet.name}>
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: 180, // or whatever constraint you like
                            display: 'inline-block',
                          }}
                        >
                          {questionSet.name}
                        </span>
                      </Tooltip>
                    </Box>

                    {/* Show an error if the set "failed" */}
                    {isFailed && (
                      <Typography
                        sx={{
                          color: 'error.main',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          ml: 2,
                        }}
                      >
                        Für dieses Set konnten leider keine Fragen erstellt werden.
                      </Typography>
                    )}

                    {/* If in_progress => show progress or spinner */}
                    {isInProgress && !isFailed && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          flexGrow: 1,
                          minWidth: 0,
                        }}
                      >
                        {quizProgress > 0 ? (
                          // A single line with progress bar + "Fragenerstellung läuft..."
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              width: '50%',
                              minWidth: 0,
                            }}
                          >
                            {/* progress bar */}
                            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                              <LinearProgressWithLabel
                                value={quizProgress}
                                sx={{
                                  height: 8,
                                  backgroundColor: (theme) =>
                                    theme.palette.grey[300],
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: (theme) =>
                                      theme.palette.grey[600],
                                  },
                                }}
                              />
                            </Box>

                            {/* Label text */}
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: 'grey.600',
                                whiteSpace: 'nowrap',
                                flexShrink: 0,
                              }}
                            >
                              Fragenerstellung läuft...
                            </Typography>
                          </Box>
                        ) : (
                          // Spinner + "Yeah! Wir bereiten..."
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <CircularProgress
                              size={20}
                              sx={{ color: (theme) => theme.palette.grey[600] }}
                            />
                            <Typography
                              sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                              }}
                            >
                              Yeah! Wir bereiten alles vor...
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>

                  {/* Right side: Edit + Delete Buttons */}
                  <Box
                    sx={{ display: 'flex', gap: 1 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <EditQuestionSetButton
                      questionSetId={questionSet.id}
                      onQuestionSetUpdated={onQuestionSetUpdated}
                    />
                    <DeleteQuestionSetButton
                      questionSetId={questionSet.id}
                      onQuestionSetUpdated={onQuestionSetUpdated}
                    />
                  </Box>
                </Box>
              );
            })
        )}
      </Box>
    </Paper>
  );
};

export default QuizList;
