import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Paper, Box, Button } from '@mui/material';
import { fetchQuestionsByQuestionSet } from '../../services/GetQuestionsService';
import { fetchQuestionSetById } from '../../services/GetQuestionSetService';
import './AnsweringMode.css';
import TextField from '@mui/material/TextField';
import ENDPOINTS from '../../utils/apiConfig';
import { refreshToken } from '../../utils/AuthenticationService';
import FullPointsAnswerGif from '../../assets/Full_Points_Answer.gif';
import Icon from '../../components/Icon'; 
import AnsweringMenu from '../../components/Menus/AnweringMenu';
import { fetchSingleQuestion } from '../../services/GetSingleQuestionService';
import AnsweringStatisticsMenu from '../../components/Menus/AnsweringStatisticMenu';
import CircularProgress from '@mui/material/CircularProgress';


const getPointsIcon = (rating) => {
  if (rating == null) return null; // Handle null/undefined ratings

  switch (String(rating)) {
    case '0':
      return <Icon iconName="zeroCircle" />;
    case '1':
      return <Icon iconName="oneCircle" />;
    case '2':
      return <Icon iconName="twoCircle" />;
    case '3':
      return <Icon iconName="threeCircle" />;
    case '4':
      return <Icon iconName="fourCircle" />;
    case '5':
      return <Icon iconName="fiveCircle" />;
    default:
      return null;
  }
};



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AnsweringMode = ({ open, onClose, questionSetId }) => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [questionSetName, setQuestionSetName] = useState('');
  const [currentIndex, setCurrentIndex] = useState(() => {
    const savedIndex = localStorage.getItem(`answeringModeIndex-${questionSetId}`);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [isFlipped, setIsFlipped] = useState(false);
  const [displayContent, setDisplayContent] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [feedback, setFeedback] = useState(null); 
  const [isChecking, setIsChecking] = useState(false);
  const [isShuffled, setIsShuffled] = useState(false);
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [isWiggling, setIsWiggling] = useState(false);
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const currentQuestion = currentQuestions && currentQuestions.length > 0 
  ? currentQuestions[currentIndex] 
  : null;
  const currentRating = currentQuestion ? currentQuestion.response_status : null;
  const PointsIcon = getPointsIcon(currentRating);
  const [unshuffledQuestions, setUnshuffledQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  



  const shuffleQuestions = (questions) => {
    let shuffled = [...questions];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap
    }
    return shuffled;
  };

  const toggleShuffle = () => {
    setIsWiggling(true);
    setTimeout(() => setIsWiggling(false), 600);
  
    // === Clear old feedback stuff ===
    setFeedback(null);
    setUserAnswer('');
    setIsFlipped(false);
    setIsChecking(false);
  
    if (!isShuffled) {
      // Save the current unshuffled
      setUnshuffledQuestions(currentQuestions);
      // Shuffle and set
      const shuffled = shuffleQuestions(currentQuestions);
      setCurrentQuestions(shuffled);
      setIsShuffled(true);
    } else {
      // restore from unshuffled
      setCurrentQuestions(unshuffledQuestions);
      setIsShuffled(false);
    }
  };
  
  
  useEffect(() => {
    if (!isShuffled) {
      setShuffledQuestions([]); // Clear shuffled questions when toggling off
    }
  }, [isShuffled]);

  useEffect(() => {
    console.log("Questions have been set or updated:", questions);
    console.log("Shuffled questions are now:", shuffledQuestions);
  }, [questions, shuffledQuestions]);
  
  useEffect(() => {
    console.log("Current Index updated to:", currentIndex);
  }, [currentIndex]);
  

  

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start the loading spinner
      try {
        const questionData = await fetchQuestionsByQuestionSet(questionSetId);
        if (questionData.length > 0) {
          setQuestions(questionData);
        }
        const questionSetData = await fetchQuestionSetById(questionSetId);
        setQuestionSetName(questionSetData.name);
      } catch (error) {
        console.error('Failed to fetch question set details:', error);
      } finally {
        setIsLoading(false); // Stop the spinner when done
      }
    };
  
    if (open) {
      fetchData();
    }
  }, [open, questionSetId]);
  

  useEffect(() => {
    localStorage.setItem(`answeringModeIndex-${questionSetId}`, currentIndex);
  }, [currentIndex, questionSetId]);

  
  



const handleNextClick = () => {
  if (currentIndex < currentQuestions.length - 1) {
    setCurrentIndex(currentIndex + 1);
  } else {
    setCurrentIndex(0); // Loop back to the first question
  }
  setIsFlipped(false);
  setUserAnswer('');    // Clear user answer
  setFeedback(null);    // Clear feedback
  setIsChecking(false); // Reset checking status
};

const handlePrevClick = () => {
  if (currentIndex > 0) {
    setCurrentIndex(currentIndex - 1);
  } else {
    setCurrentIndex(currentQuestions.length - 1); // Loop back to the last question
  }
  setIsFlipped(false);
  setUserAnswer('');    // Clear user answer
  setFeedback(null);    // Clear feedback
  setIsChecking(false); // Reset checking status
};

useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.key === 'ArrowLeft') {
      handlePrevClick();
    } else if (event.key === 'ArrowRight') {
      handleNextClick();
    }
  };

  if (open) {
    window.addEventListener('keydown', handleKeyDown);
  }

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [open, handlePrevClick, handleNextClick]);


const handleUserAnswerChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleSubmitAnswer = async () => {
    if (!questions[currentIndex] || !userAnswer.trim()) {
      setErrorMessage('Bitte gib eine Antwort ein.'); 
      return;
    }
    setErrorMessage('');
    setFeedback(null);
    setIsChecking(true); // Set checking state to true to show loading message
  
    const apiUrl = ENDPOINTS.CREATE_SINGLE_RESPONSE_ASSESSMENT;
    const requestBody = {
      question: currentQuestions[currentIndex].id, // Assuming `id` is the attribute for question ID
      user_answer: userAnswer
    };
  
    let accessToken = localStorage.getItem('accessToken'); // Retrieve the access token from localStorage
  
    try {
      let response = await makeAuthenticatedRequest(apiUrl, requestBody, accessToken);
  
      if (!response.ok) {
        if (response.status === 401) {
          // Access token might have expired, try refreshing it
          accessToken = await refreshToken(); // Refresh token using your utility
          localStorage.setItem('accessToken', accessToken); // Update the stored token
          response = await makeAuthenticatedRequest(apiUrl, requestBody, accessToken); // Retry the request with the new token
        }
        if (!response.ok) {
          const errorData = await response.json();
          console.error('API Error:', errorData);
          throw new Error(errorData.detail || 'Unknown API error');
        }
      }
  
      const result = await response.json();
      console.log("Assessment created:", result);
      fetchAssessmentFeedback(result.id);
    } catch (error) {
      console.error('Failed to create assessment:', error);
      setIsChecking(false); // Ensure to turn off the loading indicator on error
    }
  };

  const refetchQuestionWithUpdatedStatus = async (questionId) => {
    try {
      const updatedQuestion = await fetchSingleQuestion(questionId);
      // updatedQuestion now has the new "response_status"
  
      // 2) Update it in our local questions array
      setQuestions((prevQuestions) => {
        const newQuestions = [...prevQuestions];
        const index = newQuestions.findIndex((q) => q.id === questionId);
        if (index !== -1) {
          newQuestions[index] = updatedQuestion;  // replace with updated question
        }
        return newQuestions;
      });
    } catch (err) {
      console.error("Failed to refetch question:", err);
    }
  };
  
  

  const fetchAssessmentFeedback = async (assessmentId, retryCount = 0) => {
    if (!assessmentId) return;
    const maxRetries = 5; // Set a max number of retries to avoid infinite loops
    const retryInterval = 2000; // Retry every 2000 milliseconds (2 seconds)
  
    const apiUrl = ENDPOINTS.GET_SINGLE_RESPONSE_ASSESMENT(assessmentId);
    let accessToken = localStorage.getItem('accessToken'); // Retrieve the token
  
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          accessToken = await refreshToken(); // Refresh token using your utility
          localStorage.setItem('accessToken', accessToken); // Update the stored token
          return fetchAssessmentFeedback(assessmentId, retryCount); // Retry the request with the new token
        }
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Unknown API error');
      }
  
      const feedbackData = await response.json();
  
      if (feedbackData.response_status !== 'completed' && retryCount < maxRetries) {
        setTimeout(() => fetchAssessmentFeedback(assessmentId, retryCount + 1), retryInterval);
      } else {
        setIsChecking(false);
        console.log("Feedback received:", feedbackData);
        setFeedback(feedbackData); // Store the feedback data if completed or max retries reached
        const questionId = currentQuestions[currentIndex].id; 
        refetchQuestionWithUpdatedStatus(questionId);
      }
    } catch (error) {
      console.error('Failed to fetch assessment feedback:', error);
      if (retryCount < maxRetries) {
        setTimeout(() => fetchAssessmentFeedback(assessmentId, retryCount + 1), retryInterval);
      }
    }
  };
  
  
  const makeAuthenticatedRequest = async (url, data, token) => {
    return fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  };
  
  const getColorStyles = (level) => {
    switch (level) {
      case "1":
        return { borderColor: '#FFA500', backgroundColor: '#FFEFD5' }; // Orange
      case "2":
        return { borderColor: '#FFD700', backgroundColor: '#FFF8DC' }; // Gold
      case "3":
        return { borderColor: '#ADFF2F', backgroundColor: '#F0FFF0' }; // Greenyellow
      case "4":
        return { borderColor: '#7CFC00', backgroundColor: '#F5FFFA' }; // Lawngreen
      case "5":
        return { borderColor: '#00FF00', backgroundColor: '#F0FFF0' }; // Limegreen
      default:
        return { borderColor: '#D3D3D3', backgroundColor: '#F5F5F5' }; // Lightgrey for undefined levels
    }
  };

 // 1) Store user’s filter choices

 
 const [checkboxState, setCheckboxState] = useState({
  '0': true,
  '1': true,
  '2': true,
  '3': true,
  '4': true,
  '5': true,
  unrated: true,
});

const [sliderValue, setSliderValue] = useState(() => {
  const savedSlider = localStorage.getItem(`answeringSliderValue-${questionSetId}`);
  if (savedSlider) {
    const num = parseInt(savedSlider, 10);
    return (!isNaN(num) && num > 0) ? num : null;
  }
  return null;
}); 

const [totalAvailableQuestions, setTotalAvailableQuestions] = useState(0);
useEffect(() => {
  // If totalAvailableQuestions is known and sliderValue is null or less than 1,
  // set sliderValue to totalAvailableQuestions
  if (totalAvailableQuestions > 0 && (sliderValue === null || sliderValue <= 0)) {
    setSliderValue(totalAvailableQuestions);
  }
}, [totalAvailableQuestions, sliderValue]);

// 2) Handle changes from the menu
const handleCheckboxChange = (updatedState) => {
  setCheckboxState(updatedState);
};

const handleSliderChange = (newValue) => {
  setSliderValue(newValue);
};

// 3) Each time questions or filter states change, run applyFilters
useEffect(() => {
  if (!isLoading && questions.length > 0) {
    console.log("[AnsweringMode] calling applyFilters()");
    applyFilters();
  }
}, [checkboxState, sliderValue, questions, isLoading]);


const applyFilters = () => {
  // 1) Figure out which ratings the user wants to see (the ones that are true)
  const selectedRatings = Object.keys(checkboxState).filter(
    (key) => checkboxState[key] === true
  );

  // 2) Filter your questions array by rating
  const filtered = questions.filter((q) => {
    const rating = q.response_status;
    // If a question has no rating, treat it as "unrated"
    if (!rating) {
      return selectedRatings.includes('unrated');
    }
    // Else, return true if the rating is in the selectedRatings
    return selectedRatings.includes(rating);
  });

  // 3) Update totalAvailableQuestions
  const total = filtered.length;
  setTotalAvailableQuestions(total);

  // 4) If there are no questions after filtering, set empty and sliderValue = 0
  if (total === 0) {
    setCurrentQuestions([]);
    setSliderValue(0);
    return; // Return early, we’re done
  }

  // 5) Clamp the sliderValue within [1 ... total]
  //    If sliderValue is null, default it to total
  let safeSlider = sliderValue == null ? total : sliderValue;
  if (safeSlider <= 0) {
    safeSlider = total; // e.g., user typed 0 in the UI, or we had an invalid value
  }
  if (safeSlider > total) {
    safeSlider = total; // e.g., user typed 999 but we only have 10 filtered
  }

  // 6) Update the sliderValue if we had to clamp it
  if (safeSlider !== sliderValue) {
    setSliderValue(safeSlider);
  }

  // 7) Slice the first `safeSlider` questions
  const limited = filtered.slice(0, safeSlider);

  // 8) If isShuffled is true, shuffle the sliced set
  let finalSet = isShuffled ? shuffleQuestions(limited) : limited;

  // 9) Update state
  setCurrentQuestions(finalSet);

  // 10) If currentIndex >= finalSet.length, reset to 0
  if (currentIndex >= finalSet.length) {
    setCurrentIndex(0);
  }
};


// Run once when AnsweringMode mounts or questionSetId changes
useEffect(() => {
  const savedCheckboxJson = localStorage.getItem(`answeringCheckboxState-${questionSetId}`);
  if (savedCheckboxJson) {
    setCheckboxState(JSON.parse(savedCheckboxJson));
  }

  const savedSlider = localStorage.getItem(`answeringSliderValue-${questionSetId}`);
  if (savedSlider) {
    setSliderValue(Number(savedSlider));
  }
}, [questionSetId]);


useEffect(() => {
  if (sliderValue != null) {
    localStorage.setItem(`answeringSliderValue-${questionSetId}`, sliderValue);
  }
}, [sliderValue, questionSetId]);



const handleRatingsReset = async () => {
  try {
    // Re-fetch all questions from backend
    const questionData = await fetchQuestionsByQuestionSet(questionSetId);

    // Update local state
    setQuestions(questionData);

    // If needed, re-apply filters to keep everything consistent
    applyFilters(); // If "applyFilters()" is accessible here
  } catch (error) {
    console.error("Error re-fetching questions after reset:", error);
  }
};
  
  

return (
  <Dialog
    fullScreen
    open={open}
    onClose={onClose}
    TransitionComponent={Transition}
    PaperProps={{
      sx: {
        margin: 0,
        borderRadius: 0,
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        overflow: 'hidden',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        background: theme.palette.gradient.background,
        position: 'relative',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          flex: 1,
          m: 2,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgba(255, 255, 255, 0.97)',
          boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
          overflow: 'hidden',
        }}
      >
        {/* Non-scrolling Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            backgroundColor: 'transparent',
          }}
        >
          <Typography sx={{ flex: 1, color: 'grey' }} variant="h6" component="div">
            {questionSetName || 'Loading...'}
          </Typography>
          <IconButton
            edge="start"
            color="gray"
            onClick={onClose}
            aria-label="close"
            sx={{
              marginLeft: 'auto',
              width: 30,
              height: 30,
              padding: 0,
              position: 'absolute',
              top: 25,
              right: 20,
              '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
              },
            }}
          >
            <Icon iconName="cross" style={{ width: 30, height: 30 }} />
          </IconButton>
        </Box>
        {/* Content Container for both Paper and Buttons */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
            px: 2,
            pb: 6,
            mt: {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 4,
              xl: 10,
            },
          }}
        >
          <>
            {isLoading ? (
              // ---- SHOW LOADING SPINNER ----
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: '80%',
                }}
              >
                <CircularProgress size={80} sx={{ color: theme.palette.primary.main }} />
              </Box>
            ) : (
              // ---- SHOW THE MAIN CONTENT ----
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2, marginTop: 2 }}>
                <div className={`flip-container ${isWiggling ? 'wiggle' : ''}`}>
                  <div className={`flipper ${isFlipped ? 'flipped' : ''}`}>
                    <Paper
                      className="paper-content"
                      sx={{
                        p: { xs: 2, md: 3 },
                        fontSize: { xs: '0.875rem', md: '1rem' },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        minHeight: { xs: 300, md: 500 },
                        overflow: 'auto',
                        borderRadius: '20px',
                        position: 'relative',
                      }}
                    >
                      {PointsIcon && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                            width: 30,
                            height: 30,
                            color: theme.palette.primary.main,
                            '& svg': {
                              width: '100%',
                              height: '100%',
                            },
                          }}
                        >
                          {PointsIcon}
                        </Box>
                      )}
                      <Typography variant="h4" component="div" sx={{ fontSize: '1.3em', width: '100%', textAlign: 'left', mt: 1 }}>
                        {currentQuestions.length > 0 ? (
                          currentQuestions[currentIndex].content.split(/\/n|\\n/).map((line, idx) => (
                            <React.Fragment key={idx}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                        ) : (
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h4" component="div" align="center" sx={{ fontSize: '1.8em' }}>
                              Oops! Keine Frage verfügbar.
                            </Typography>
                            <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                              Bitte überprüfe deine Filtereinstellungen oder versuche es später erneut.
                            </Typography>
                          </Box>
                        )}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        multiline
                        rows={3}
                        placeholder="Gib hier deine Antwort ein"
                        value={userAnswer}
                        onChange={handleUserAnswerChange}
                        sx={{ mt: 2 }}
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmitAnswer}
                        disabled={isChecking}
                        sx={{ mt: 2, mb: 1, mx: 'auto', display: 'block' }}
                      >
                        Antwort überprüfen
                      </Button>
                      {errorMessage && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            marginTop: 2,
                            color: theme.palette.primary.main,
                            textAlign: 'center',
                            alignSelf: 'center',
                          }}
                        >
                          {errorMessage}
                        </Typography>
                      )}
                      {isChecking && (
                        <Typography variant="subtitle1" sx={{ mt: 2, color: 'gray' }}>
                          Deine Antwort wird gerade überprüft...
                        </Typography>
                      )}
                      {feedback && (
                        <Box sx={{ mt: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative',
                              width: '100%',
                              mb: 1,
                            }}
                          >
                            <Typography variant="subtitle2" color="black" sx={{ textAlign: 'center', zIndex: 1 }}>
                              {feedback ? `${feedback.correctness_level}/5 Punkte` : 'No feedback'}
                            </Typography>
                            {feedback && feedback.correctness_level === '5' && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                }}
                              >
                                <img src={FullPointsAnswerGif} alt="Celebration" style={{ width: '150px', height: 'auto' }} />
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              border: 1,
                              ...getColorStyles(feedback.correctness_level),
                              p: 1,
                              borderRadius: '8px',
                              mb: 2,
                            }}
                          >
                            <Typography variant="body2" color="black" sx={{ fontWeight: 'bold' }}>
                              Feedback:
                            </Typography>
                            <Typography variant="body2" color="black" sx={{ mb: 1 }}>
                              {feedback ? feedback.llm_feedback : 'No feedback yet'}
                            </Typography>
                          </Box>
                          <Box sx={{ border: 1, borderColor: 'grey', p: 1, borderRadius: '8px', mb: 2 }}>
                            <Typography variant="body2" color="black" sx={{ fontWeight: 'bold' }}>
                              Ideale Antwort:
                            </Typography>
                            <Typography variant="body2" color="black">
                              {feedback.ideal_answer_content.split(/\/n|\\n/).map((line, idx) => (
                                <React.Fragment key={idx}>
                                  {line}
                                  {idx < feedback.ideal_answer_content.split(/\/n|\\n/).length - 1 && <br />}
                                </React.Fragment>
                              ))}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Paper>
                  </div>
                </div>
                {/* Button Container directly below the paper */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <IconButton
                      color="secondary"
                      onClick={handlePrevClick}
                      sx={{
                        mx: 8,
                        width: { xs: 44, md: 56 },
                        height: { xs: 44, md: 56 },
                      }}
                    >
                      <Icon iconName="leftCircle" style={{ width: 40, height: 40 }} />
                    </IconButton>
                    <Typography variant="subtitle1" sx={{ userSelect: 'none' }}>
                      {`${currentIndex + 1}/${currentQuestions.length}`}
                    </Typography>
                    <IconButton
                      color="secondary"
                      onClick={handleNextClick}
                      sx={{
                        mx: 8,
                        width: { xs: 44, md: 56 },
                        height: { xs: 44, md: 56 },
                      }}
                    >
                      <Icon iconName="rightCircle" style={{ width: 40, height: 40 }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}
            <Box sx={{ position: 'absolute', bottom: 20, left: 20 }}>
              <AnsweringMenu
                pinnedStateKey={`answeringMenuPinned-${questionSetId}`}
                totalQuestions={totalAvailableQuestions}
                sliderValue={sliderValue}
                onSliderChange={handleSliderChange}
                checkboxState={checkboxState}
                onCheckboxChange={handleCheckboxChange}
                isShuffled={isShuffled}
                toggleShuffle={toggleShuffle}
              />
            </Box>
            <Box sx={{ position: 'absolute', bottom: 20, right: 20 }}>
              <AnsweringStatisticsMenu
                questions={questions}
                pinnedStateKey={`answeringStatisticsPinned-${questionSetId}`}
                questionSetId={questionSetId}
                onRatingsReset={handleRatingsReset}
              />
            </Box>
          </>
        </Box>
      </Paper>
    </Box>
  </Dialog>
);
};


export default AnsweringMode;
