// CreateSummaryDialog.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  Typography,
  Paper,
  TextField,
  Checkbox,
  Divider,
    IconButton,
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';
import Icon from '../../components/Icon';
import FileList from '../../components/Lists/FileList';
import { useNavigate } from 'react-router-dom';


const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CreateSummaryDialog = ({ open, onClose, courseId, courseName, files, filesError, onSummaryCreated }) => {
  // Page 1 states
  const [summaryName, setSummaryName] = useState('');
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  // Page 2 states
  const [selectedPages, setSelectedPages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filePages, setFilePages] = useState({});
  // For messages
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageOpen, setIsImageOpen] = useState(false);
  
  const navigate = useNavigate();

  // Reset states when dialog is closed
  useEffect(() => {
    if (!open) {
      setSummaryName('');
      setSelectedFileIds([]);
      setSelectedPages({});
      setCurrentPage(1);
      setErrorMessage('');
      setSuccessMessage('');
      setFilePages({});
    }
  }, [open]);

  // Toggle file selection
  const handleFileClick = (file) => {
    if (selectedFileIds.includes(file.id)) {
      setSelectedFileIds(selectedFileIds.filter(id => id !== file.id));
      const { [file.id]: removed, ...rest } = selectedPages;
      setSelectedPages(rest);
    } else {
      setSelectedFileIds([...selectedFileIds, file.id]);
      setSelectedPages({ ...selectedPages, [file.id]: [] });
    }
  };

  // Fetch file pages for each selected file
  const fetchFilePages = async (fileId) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(ENDPOINTS.GET_FILE_PAGE_SMALL_IMAGES(fileId), {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(`Pages for file ${fileId}:`, data); // Log to debug image preview URLs
        setFilePages(prev => ({ ...prev, [fileId]: data }));
      } else {
        console.error(`Failed to fetch pages for file ${fileId}`);
      }
    } catch (error) {
      console.error(`Error fetching pages for file ${fileId}:`, error);
    }
  };

  // Whenever the selected files change, load their pages if not already loaded
  useEffect(() => {
    selectedFileIds.forEach(fileId => {
      if (!filePages[fileId]) {
        fetchFilePages(fileId);
      }
    });
  }, [selectedFileIds]);

  // Toggle page selection for a given file
  const handlePageSelection = (fileId, index, event) => {
    setSelectedPages(prev => {
      const pagesForFile = prev[fileId] || [];
      let updatedPages;
      if (pagesForFile.includes(index)) {
        updatedPages = pagesForFile.filter(page => page !== index);
      } else {
        updatedPages = [...pagesForFile, index];
      }
      return { ...prev, [fileId]: updatedPages };
    });
  };

  // Select all (or deselect all) pages for each selected file
  const handleSelectAll = () => {
    const newSelectedPages = {};
    selectedFileIds.forEach(fileId => {
      if (filePages[fileId]) {
        if (selectedPages[fileId] && selectedPages[fileId].length === filePages[fileId].length) {
          newSelectedPages[fileId] = [];
        } else {
          newSelectedPages[fileId] = filePages[fileId].map((_, index) => index);
        }
      }
    });
    setSelectedPages(newSelectedPages);
  };

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  const handlePreviousPage = () => {
    setCurrentPage(1);
  };

  // Submit the summary creation request
  const handleSubmit = async () => {
    const payload = {
      course_id: courseId,
      name: summaryName,
      files: Object.entries(selectedPages).map(([fileId, pages]) => ({
        file_id: parseInt(fileId, 10),
        pages: pages.map(index => index + 1) // convert from 0-index to 1-index
      }))
    };

    try {
      const response = await fetch(ENDPOINTS.SUMMARY_AI_GENERATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.detail || 'Fehler beim Erstellen der Zusammenfassung.');
        return;
      }

      const data = await response.json();
      setSuccessMessage('Zusammenfassung wird erstellt!');
      if (onSummaryCreated) {
        onSummaryCreated(data);
      }
      setTimeout(() => {
        navigate(`/course/${courseId}?tab=3`);
        onClose();
      }, 1500);
    } catch (error) {
      console.error('Error creating summary:', error);
      setErrorMessage('Netzwerkfehler. Bitte versuche es erneut.');
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageOpen(true);
  };

  
  // Handler: closes the image
  const handleCloseImage = () => {
    setIsImageOpen(false);
    setSelectedImage(null);
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" PaperProps={{ sx: { borderRadius: '20px' } }}>
      {currentPage === 1 ? (
        <>
          <DialogContent sx={{ ml: 3, mr: 6 }}>
            <DialogTitle sx={{ pl: 0 }}>Zusammenfassung erstellen</DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ mb: 2 }}>
                <DialogTitle sx={{ pl: 0 }}>Name</DialogTitle>
                <DialogContentText sx={{ pl: 0 }}>
                  Bitte gib einen Namen für deine Zusammenfassung ein.
                </DialogContentText>
                <TextField
                  autoFocus
                  id="summaryName"
                  label="Zusammenfassungsname"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={summaryName}
                  onChange={(e) => setSummaryName(e.target.value)}
                  sx={{ mt: 2 }}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <DialogTitle sx={{ pl: 0 }}>Datei</DialogTitle>
                <DialogContentText sx={{ pl: 0, mb: 2 }}>
                  Bitte wähle die Dateien aus, auf deren Basis deine Zusammenfassung erstellt werden soll.
                </DialogContentText>
                <FileList
                  files={files}
                  fikeError={filesError}
                  currentFileIds={selectedFileIds}
                  onFileClick={handleFileClick}
                  courseName={courseName}
                  fileControlComponent={(file) => (
                    <Checkbox
                      checked={selectedFileIds.includes(file.id)}
                      onChange={() => handleFileClick(file)}
                      color="secondary"
                    />
                  )}
                  style={{ height: '400px', overflowY: 'auto' }}
                  processingMessage="Die Datei ist noch nicht bereit für die Zusammenfassungserstellung. Bitte warte kurz."
                  isFileReady={{}}
                  showEditAndDeleteButtons={false}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleNextPage} color="primary" disabled={!summaryName || selectedFileIds.length === 0}>
              Weiter
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent sx={{ ml: 3, mr: 6 }}>
  <DialogTitle sx={{ pl: 0 }}>Zusammenfassung erstellen</DialogTitle>
  <Box sx={{ display: 'flex', height: '100%' }}>
    <Box
      sx={{
        width: '20%',
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>Name</Typography>
      <Typography color="grey" sx={{ mb: 2 }}>{summaryName}</Typography>
      <Typography sx={{ mb: 2 }} variant="h6">Dateien</Typography>
      <Typography color="grey" sx={{ wordBreak: 'break-word' }}>
        {selectedFileIds
          .map(fileId => files.find(file => file.id === fileId)?.file_name)
          .join(', ')}
      </Typography>
    </Box>
    <Divider orientation="vertical" flexItem sx={{ height: 'auto', alignSelf: 'center' }} />

    <Box sx={{ flex: 1, padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Seiten auswählen</Typography>
        <Button onClick={handleSelectAll} color="primary" variant="outlined">
          Alle auswählen
        </Button>
      </Box>
      <DialogContentText sx={{ pl: 0, mb: 2 }}>
        Bitte wähle die Seiten deiner Dateien aus, die für die Zusammenfassung berücksichtigt werden sollen.
      </DialogContentText>

      {/* The updated Paper + Box layout, mirroring your quiz dialog */}
      <Paper elevation={4} sx={{ p: 2, height: '545px', overflowY: 'auto' }}>
        {Object.entries(filePages).map(([fileId, pages]) => (
          <Box key={fileId} sx={{ mb: 2 }}>
            <Typography sx={{ mb: 1 }}>
              {files.find(file => file.id === parseInt(fileId, 10))?.file_name}
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              {pages.map((page, index) => (
                <Box
                  key={`${fileId}-${index}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '18%',
                  }}
                >
                  {/* Clickable image box */}
                  <Box
                    sx={{
                      width: '100%',
                      border: selectedPages[fileId]?.includes(index)
                        ? '2px solid black'
                        : '1px solid grey',
                      borderRadius: '4px',
                      backgroundColor: selectedPages[fileId]?.includes(index)
                        ? 'grey.300'
                        : 'inherit',
                      p: 1,
                      cursor: 'pointer',
                    }}
                    onClick={(e) => handlePageSelection(fileId, index, e)}
                  >
                    <Box
                      component="img"
                      src={`${BASE_URL}${page.image_url}`}
                      alt={`Page ${index + 1}`}
                      sx={{ width: '100%', borderRadius: '4px' }}
                    />
                  </Box>

                  {/* "Details" button to view a larger preview */}
                  <Button
                    size="small"
                    sx={{
                      mt: 1,
                      display: 'block',
                      mx: 'auto',
                      textTransform: 'none',
                      fontSize: '0.8rem',
                      width: '80%',
                      color: 'grey.700',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageClick(`${BASE_URL}${page.image_url}`);
                    }}
                  >
                    Details
                  </Button>
                </Box>
              ))}
            </Box>
            <Divider sx={{ my: 1 }} />
          </Box>
        ))}
      </Paper>
    </Box>
  </Box>
</DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
            <Button onClick={handlePreviousPage} color="primary">
              Zurück
            </Button>
            <Button
              onClick={handleSubmit}
              color="secondary"
              variant="contained"
              disabled={Object.values(selectedPages).every(pages => pages.length === 0)}
            >
              {successMessage ? successMessage : errorMessage ? errorMessage : "Zusammenfassung erstellen"}
            </Button>
          </DialogActions>
          <Dialog open={isImageOpen} onClose={handleCloseImage} maxWidth="lg">
        <IconButton
          onClick={handleCloseImage}
          sx={{
            marginLeft: 'auto',
            width: 30,
            height: 30,
            padding: 0,
            marginTop: '20px',
            marginRight: '20px',
          }}
        >
          <Icon iconName="cross" />
        </IconButton>
        <DialogContent>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              alt="Selected Page"
              sx={{
                width: '100%',
                borderRadius: '4px',
              }}
            />
          )}
        </DialogContent>
      </Dialog>
        </>
      )}
    </Dialog>
  );
};

export default CreateSummaryDialog;
