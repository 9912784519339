import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { getTheme } from '../styles/theme';
import ENDPOINTS from '../utils/apiConfig';
import { useAuth } from '../contexts/AuthContext';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const authContext = useAuth(); // Get the context
    if (!authContext) {
        throw new Error('ThemeProvider must be used within an AuthProvider');
    }

    const { isAuthenticated, loading } = authContext; // Ensure destructuring after the check
    const [themeMode, setThemeMode] = useState('purple'); // Default theme mode
    const [customColor, setCustomColor] = useState('#5B327B'); // Default color

    useEffect(() => {
        const fetchColorPreference = async () => {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                console.error('No access token available.');
                return; // Exit if no token
            }

            console.log("Fetching color preference from:", ENDPOINTS.INTERFACE);

            try {
                const response = await fetch(ENDPOINTS.INTERFACE, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });

                console.log("Response status:", response.status);
                if (!response.ok) {
                    const textResponse = await response.text();
                    console.error(`Failed to fetch color preference: HTTP status ${response.status}`);
                    console.error('Response text:', textResponse);
                    return;
                }

                const data = await response.json();
                console.log('Received color preference:', data);
                setCustomColor(data.primary_color || '#5B327B');
                setThemeMode('individual');
            } catch (error) {
                console.error('Error fetching color preference:', error);
            }
        };

        // Fetch the color preference only if the user is authenticated and loading is complete
        if (isAuthenticated && !loading) {
            fetchColorPreference();
        }
    }, [isAuthenticated, loading]); // Depend on authentication state and loading

    const theme = useMemo(() => getTheme(themeMode, customColor), [themeMode, customColor]);

    const toggleTheme = (mode) => {
        setThemeMode(mode);
    };

    useEffect(() => {
        const root = document.documentElement;
        const primaryColor = theme.palette.primary.main;
        const secondaryColor = theme.palette.secondary.main;
        const gradientBackground = theme.palette.gradient.background;
        
        root.style.setProperty('--primary-color', primaryColor);
        root.style.setProperty('--secondary-color', secondaryColor);
        root.style.setProperty('--gradient-background', gradientBackground);
    }, [theme]);

    const updateCustomColor = (color) => {
        setCustomColor(color); // Update the custom color
    };

    return (
        <ThemeContext.Provider value={{ toggleTheme, updateCustomColor, themeMode, setThemeMode, customColor, setCustomColor }}>
            <MUIThemeProvider theme={theme}>
                {children}
            </MUIThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => useContext(ThemeContext);
