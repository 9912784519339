import React from 'react';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import Icon from '../../components/Icon'; 
import DeleteSummaryButton from '../Button/DeleteSummaryButton';

function LinearProgressWithLabel({ value, sx, ...props }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 8,
            borderRadius: '10px',
            backgroundColor: (theme) => theme.palette.grey[300],
            '& .MuiLinearProgress-bar': {
              borderRadius: '10px',
            },
            ...sx,
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'grey.700' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const SummaryList = ({
  summaries,
  onSummaryClick,
  courseName,
  summaryProgress,
onSummariesRefresh,
}) => {
  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: { md: '400px', lg: '500px', xl: '600px' },
        width: '100%',
        borderRadius: '20px',
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'left',
          pl: 1,
          pb: 2,
          backgroundColor: 'background.paper',
          width: '100%',
        }}
      >
        {courseName} - Meine Zusammenfassungen
      </Typography>

      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          '&::-webkit-scrollbar': {
            width: '0.5em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '4px',
          },
        }}
      >
        {summaries.length === 0 ? (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2,
            }}
          >
            Du hast noch keine Zusammenfassungen erstellt.
          </Typography>
        ) : (
          summaries
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((summary) => {
              // Suppose the backend returns "status" and "bulletpoints_progress_percentage"
              const isFailed = summary.status === 'failed';
              // Some backends label "pending" or "in_progress" for WIP:
              const isInProgress = summary.status === 'in_progress' || summary.status === 'pending';
              const bulletProgress = summary.bulletpoints_progress_percentage || 0;

              return (
                <Box
                  key={summary.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    p: 2,
                    width: '100%',
                    '&:hover': {
                      backgroundColor: 'grey.200',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => onSummaryClick(summary)}
                >
                  {/* Left side: Icon + Name + (Status UI) */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      minWidth: 0,
                      flexShrink: 1,
                      flexGrow: 1,
                      overflow: 'hidden',
                    }}
                  >
                    {/* Icon + Name Container */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        minWidth: 0,
                        flexShrink: 0,
                        overflow: 'hidden',
                      }}
                    >
                      <Icon iconName="summary" style={{ height: 30, width: 30 }} />

                      <Tooltip title={summary.name}>
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: 180,
                            display: 'inline-block',
                          }}
                        >
                          {summary.name}
                        </span>
                      </Tooltip>
                    </Box>

                    {/* If summary creation failed */}
                    {isFailed && (
                      <Typography
                        sx={{
                          color: 'error.main',
                          fontSize: '14px',
                          whiteSpace: 'nowrap',
                          ml: 2,
                        }}
                      >
                        Zusammenfassung fehlgeschlagen
                      </Typography>
                    )}

                    {/* If summary is in progress => show progress or spinner */}
                    {isInProgress && !isFailed && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          flexGrow: 1,
                          minWidth: 0,
                          ml: 2,
                        }}
                      >
                        {bulletProgress > 0 ? (
                          // A single line with progress bar + "Zusammenfassung läuft..."
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              width: '50%',
                              minWidth: 0,
                            }}
                          >
                            {/* progress bar */}
                            <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                              <LinearProgressWithLabel
                                value={bulletProgress}
                                sx={{
                                  height: 8,
                                  backgroundColor: (theme) => theme.palette.grey[300],
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: (theme) => theme.palette.grey[600],
                                  },
                                }}
                              />
                            </Box>

                            {/* Label text */}
                            <Typography
                              sx={{
                                fontSize: '14px',
                                color: 'grey.600',
                                whiteSpace: 'nowrap',
                                flexShrink: 0,
                              }}
                            >
                              Zusammenfassung läuft...
                            </Typography>
                          </Box>
                        ) : (
                          // Spinner + "Yeah! Wir bereiten..."
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <CircularProgress
                              size={20}
                              sx={{ color: (theme) => theme.palette.grey[600] }}
                            />
                            <Typography
                              sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                              }}
                            >
                              Yeah! Wir bereiten deine Zusammenfassung vor...
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>

                  <Box
                    sx={{ display: 'flex', gap: 1 }}
                    onClick={(e) => e.stopPropagation()} // so we don't trigger onSummaryClick
                    >
                    <DeleteSummaryButton
                    summaryId={summary.id}
                    onSummaryUpdated={() => {
                    console.log('Summary was deleted! Time to refresh the list or update state.');
                    onSummariesRefresh?.(); 
                    }}
                    />
                </Box>
                </Box>
              );
            })
        )}
      </Box>
    </Paper>
  );
};

export default SummaryList;
