import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';
import Icon from '../../components/Icon';
import { fetchUserDetails } from '../../services/UserService'; // Import the user details service

// Enhanced, personalized CTA messages with a placeholder {username}
const enhancedCTAMessages = [
  "Na {username}, sei ganz ehrlich – wir vertragen auch mal harte Worte!",
  "Hey {username}, raus mit der Sprache: Dein Feedback macht uns schlauer!",
  "Na {username}, jetzt mal Butter bei die Fische – sag uns, was Sache ist!",
  "Hey {username}, keine halben Sachen: Wir wollen dein ehrliches Feedback, egal wie frech es ist!",
];

const FeedbackDialog = ({ open, onClose, onFeedbackSubmitted }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const location = useLocation();
  const [ctaMessage, setCtaMessage] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [userName, setUserName] = useState(''); // Store the user's name

  // Get context from URL (page and tab)
  const page = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  // When the dialog opens, fetch the user's details and choose a random CTA message
  useEffect(() => {
    if (open) {
      // Fetch user details so we can address the user by name
      fetchUserDetails()
        .then((data) => {
          // Assume the returned data has a 'username' property
          setUserName(data.username || '');
        })
        .catch((error) => {
          console.error('Error fetching user details:', error);
          setUserName(''); // Fallback if fetching fails
        });

      // Pick a random CTA message and replace the placeholder with the user's name (if available)
      const randomMsg = enhancedCTAMessages[Math.floor(Math.random() * enhancedCTAMessages.length)];
      // We use a function to perform the replacement once the username is available.
      setCtaMessage(randomMsg);
      setFeedbackSubmitted(false);
      setFeedbackText('');
    }
  }, [open]);

  // Once the username is fetched, update the CTA message with it
  useEffect(() => {
    if (userName && ctaMessage.includes('{username}')) {
      const personalizedMessage = ctaMessage.replace('{username}', userName);
      setCtaMessage(personalizedMessage);
    }
  }, [userName, ctaMessage]);

  const handleSend = async () => {
    if (feedbackText.trim() === '') {
      alert('Bitte gib dein Feedback ein.');
      return;
    }

    const feedbackData = {
      feedback_text: feedbackText,
      page,
      ...(tab && { tab }),
    };

    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(ENDPOINTS.FEEDBACK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        setFeedbackSubmitted(true);
        setFeedbackText('');
        if (onFeedbackSubmitted) {
          onFeedbackSubmitted();
        }
        // Auto-close the dialog after a short delay
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        const errorData = await response.json();
        console.error('Error submitting feedback:', errorData);
        alert('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.');
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };

  const handleCancel = () => {
    setFeedbackText('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '20px',
          p: 2,
        },
      }}
    >
      {/* Headline and Subheading */}
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: 'primary.main', mb:3, }}>
          {userName ? `Hey ${userName}, sag uns, was du denkst!` : 'Deine Meinung zählt!'}
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary' }}>
          Wir bauen Thinki für DICH – teile deine Gedanken, Ideen und Kritik mit uns! Je detaillierter dein Feedback, desto besser können wir die App auf deine Bedürfnisse zuschneiden.
        </Typography>
      </Box>

      <DialogContent>
        {/* Engaging CTA message */}
        <Box sx={{ mb: 1, fontSize: '0.9em', color: 'text.secondary', textAlign: 'center' }}>
          {ctaMessage}
        </Box>
        <TextField
          autoFocus
          margin="dense"
          label="Dein Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          disabled={feedbackSubmitted}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
        <Button onClick={handleCancel} color="secondary" disabled={feedbackSubmitted}>
          Abbrechen
        </Button>
        {!feedbackSubmitted ? (
          <Button onClick={handleSend} color="primary" variant="contained">
            Senden
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={<Icon iconName="check" />}
            sx={{ animation: 'pulse 1s infinite alternate' }}
          >
            Danke!
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
