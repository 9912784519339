import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import EditFileButton from '../Button/EditFileButton';
import DeleteFileButton from '../Button/DeleteFileButton';
import DownloadFileButton from '../Button/DownloadFileButton';
import Icon from '../../components/Icon';
import { useTheme } from '@mui/material/styles';

// Helper: check if file fully processed
const isFileFullyProcessed = (file) =>
  file?.text_status === 'completed' &&
  file?.images_status === 'completed' &&
  file?.metadata_status === 'completed';

const FileList = ({
  files,
  filesError,
  isLoading,
  inProgressFileIds = [],
  onFileClick,
  onFileUpdated,
  courseName,
  fileControlComponent,
  style,
  showEditAndDeleteButtons = true
}) => {
  const theme = useTheme();
  const [recentlyFinishedFileIds, setRecentlyFinishedFileIds] = useState([]);

  useEffect(() => {
    // Whenever files or inProgressFileIds change...
    files.forEach((file) => {
      const isFinished = isFileFullyProcessed(file);
      const wasInProgress = inProgressFileIds.includes(file.id);

      if (isFinished && wasInProgress) {
        // Mark file as recently finished => show "Datei ist bereit!" for 3s
        setRecentlyFinishedFileIds((prev) =>
          prev.includes(file.id) ? prev : [...prev, file.id]
        );

        setTimeout(() => {
          setRecentlyFinishedFileIds((prev) => prev.filter((id) => id !== file.id));
        }, 3000);
      }
    });
  }, [files, inProgressFileIds]);

  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: { md: '400px', lg: '500px', xl: '600px' },
        borderRadius: '20px',
        width: '100%',
       
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'left',
          pl: 1,
          pb: 2,
          backgroundColor: 'background.paper',
          width: '100%'
        }}
      >
        {courseName} - Meine Dateien
      </Typography>

      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          '&::-webkit-scrollbar': { width: '0.5em' },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '4px',
          }
        }}
      >
        {/* Loading State */}
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100%',
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : filesError ? (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2
            }}
          >
            Error: {filesError}
          </Typography>
        ) : files.length > 0 ? (
          files.map((file) => {
            const isFinished = isFileFullyProcessed(file);
            const isInProgress = inProgressFileIds.includes(file.id);
            const showReadyMessage = recentlyFinishedFileIds.includes(file.id);

            return (
              <Box
                key={file.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 2,
                  p: 2,
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'grey.200',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => onFileClick(file)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    maxWidth: 'calc(100% - 48px)',
                    overflow: 'hidden'
                  }}
                >
                  {/* If you passed a custom fileControlComponent, use it, else show default icon */}
                  {fileControlComponent ? (
                    fileControlComponent(file)
                  ) : (
                    <Icon iconName="file" sx={{ color: theme.palette.primary.main }} />
                  )}

                  {/* File Name */}
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {file.file_name}
                  </span>

                  {/* Spinner + status text if in progress */}
                  { !isFinished && (
                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      <CircularProgress
                        size={20}
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      />
                      {file.text_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Texte werden verarbeitet...
                        </Typography>
                      ) : file.images_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Bilder werden verarbeitet...
                        </Typography>
                      ) : file.metadata_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Jetzt nur noch die Metadaten...
                        </Typography>
                      ) : null}
                    </Box>
                  )}

                  {/* If the file just finished, show "Datei ist bereit!" for 3s */}
                  {showReadyMessage && (
                    <Typography sx={{ ml: 2, color: 'green' }}>
                      Datei ist bereit!
                    </Typography>
                  )}
                </Box>

                {/* Action Buttons */}
                <Box
                  sx={{ display: 'flex', gap: 1 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {showEditAndDeleteButtons && (
                    <>
                      <EditFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                      <DeleteFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                      <DownloadFileButton fileId={file.id} fileName={file.file_name} />
                    </>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2
            }}
          >
            Du hast noch keine Dateien hochgeladen. Bitte lade zunächst eine Datei hoch.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default FileList;
